import React from 'react'
import Layout from '../components/Layout'

export default function Error404() {
    return (
        <Layout>
            <div>
                <h1>Ooops... Error 404 Not Found</h1>
            </div>
        </Layout>
    )
}
